import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Entrepreneurs = () => {
    return (
        <Layout>
            <Seo
                title="Entrepreneurs - BIKESAFE"
                description="Tous les produits de Bike Safe sont fabriqués à partir de matériaux de haute qualité et grâce à d'excellentes méthodes de fabrication. Nos supports vélos ne demandent donc aucun entretien..."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Entrepreneurs
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">Qualité et fonctionnalité :</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                            Tous les produits de Bike Safe sont fabriqués à partir de matériaux de haute qualité et grâce à d'excellentes méthodes de fabrication. Nos supports vélos ne demandent donc aucun entretien. Nous avons recours à des soudures continues et à des procédures de finition de pointe. Tous les racks à vélos sont dotés d'une finition galvanisée par immersion à chaud (conforme à la norme BS 1461:1999) ou d'un revêtement en poudre polyester. Tous nos produits sont fabriqués au Royaume-Uni par des opérateurs et ouvriers qualifiés.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="mt-5 mb-5 text-center">
                        <StaticImage placeholder="blurred"  src="../images/entrepreneurs.jpg" className="shadow fluid rounded" />
                        <em className="flow-text">« Vos délais étaient courts et il fut très facile et agréable de travailler avec vous »
                            Lee Irvine, Irvine Utilities, Londres</em>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <h3>Les produits Bike Safe présentent les caractéristiques suivantes :</h3>
                        <ul className="mt-3">
                            <li className="border p-2 shade-on-hover">Aucun besoin d'entretien des supports vélos et équipements de stationnement : nos supports vélos, racks et abris à vélos sont très résistants et conçus pour durer.</li>
                            <li className="border p-2 shade-on-hover">Facilité d'installation sur toute surface</li>
                            <li className="border p-2 shade-on-hover">Esthétique : un support vélo ou système de stationnement pour vélos esthétique, situé devant un bâtiment, met en valeur son architecture et maximise son utilisation.</li>
                            <li className="border p-2 shade-on-hover">Rangement efficace et ordonné des vélos</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Entrepreneurs;